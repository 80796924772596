export const portfolioImages = [
    { id: 1, name: 'Blunt Times', image: 'blunt-times.jpeg' },
    { id: 2, name: 'Dhabkar Article', image: 'dhabkar-article.jpeg' },
    { id: 3, name: 'Dhabkar E Passport', image: 'dhabkar-e-passport.jpeg' },
    { id: 3, name: 'Green Card', image: 'green-card.jpeg' },
    { id: 4, name: 'Green Card Eagle Act', image: 'green-card-eagle-act.jpeg' },
    { id: 4, name: 'Gujarati Mitra Canada', image: 'gujarati-mitra-canada.jpeg' },
    { id: 4, name: 'Gujarat Mitra', image: 'gujarat-mitra.jpeg' },
    { id: 4, name: 'Lok Tej Article', image: 'lok-tej-article.jpeg' },
    { id: 4, name: 'Lok Tej Digital Kranti Article', image: 'lok-tej-digital-kranti-article.jpeg' },
    { id: 4, name: 'Lok Tej E Passport Article', image: 'lok-tej-e-passport-article.jpeg' },
    { id: 4, name: 'Lok Tej French Article', image: 'lok-tej-french-article.jpeg' },
    { id: 4, name: 'Samruddhi Article', image: 'samruddhi-article.jpeg' },
    { id: 4, name: 'Samruddhi E Passport', image: 'samruddhi-e-passport.jpeg' },
    { id: 4, name: 'Sardar Dham Article', image: 'sardar-dham-article.jpeg' },
    { id: 4, name: 'Tax Val Article', image: 'tax-val-article.jpeg' },
    { id: 4, name: 'The Blunt Times Gst Article', image: 'the-blunt-times-gst-article.jpeg' },
];
