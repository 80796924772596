export const testimonialsData = [
  {
    "id": 1,
    "clientName": "Utsav",
    "clientDesignation": "IT Professional",
    "quote": "I was very confused about the immigration process, but he made everything clear and easy for me. His help was invaluable, and I'm grateful for his support. I highly recommend his services to anyone in need of immigration assistance."
  },
  {
    "id": 2,
    "clientName": "Anjali",
    "clientDesignation": "Teacher",
    "quote": "I was worried about my immigration status, but he helped me every step of the way. He are very knowledgeable and friendly. I highly recommend them. Thanks to his expertise, I can now pursue my career goals without any worries."
  },
  {
    "id": 3,
    "clientName": "Satish",
    "clientDesignation": "Small Business Owner",
    "quote": "I needed help with my visa application, and he were amazing. He explained everything clearly and made the process smooth. Thank you for your excellent service. With his support, my business expansion plans are now a reality."
  },
  {
    "id": 4,
    "clientName": "Vipul",
    "clientDesignation": "Student",
    "quote": "I'm so thankful to them for helping me with my immigration paperwork. He are very patient and understanding. I couldn't have done it without them. With his guidance, I feel confident about my future in this new country."
  }
]
